import { clsx } from "clsx";
import { useParams } from "react-router-dom";

import { priceFormatter } from "@repo/system";
import { T, useT } from "@repo/transifex";
import type { Currency, PaymentMethods, ReceiptReport } from "@repo/types";
import { ReportStatus } from "~/components";
import { useSalesReport, useTills } from "~/hooks/queries";
import { useAuth } from "~/providers/store/auth";

type TitleTextProps = {
  title: string;
  className?: string;
};

export const TitleText = ({ title, className }: TitleTextProps) => (
  <p
    className={clsx(
      "mb-2.5 text-2xl font-medium text-secondary-contrast",
      className
    )}
  >
    <T _str={title} />
  </p>
);

export const SubtitleText = ({ title, className }: TitleTextProps) => (
  <p
    className={clsx(
      "mb-1 text-lg font-medium text-secondary-contrast",
      className
    )}
  >
    <T _str={title} />
  </p>
);

type InfoTextProps = {
  value: string | number;
} & TitleTextProps;

export const InfoText = ({ title, value, className }: InfoTextProps) => (
  <div
    className={clsx(
      "flex justify-between border-b-2 border-divider-main py-2.5",
      className
    )}
  >
    <p className="text-text-primary">
      <T _str={title} />
    </p>
    <p className="font-medium text-text-primary">{value}</p>
  </div>
);

export const InfoTextWithPriceFormat = ({
  title,
  value,
  className,
  currency,
  percentage,
}: InfoTextProps & {
  currency: Currency | null | "";
  percentage?: number;
}) => {
  const { locale } = useAuth();

  const titleWithCurrency =
    title +
    (currency ? ` ${currency}` : "") +
    (typeof percentage === "number" ? ` (${percentage}%)` : "");

  return (
    <InfoText
      className={className}
      title={titleWithCurrency}
      value={
        value && currency
          ? priceFormatter({ value: Number(value) * 100, currency, locale })
          : "-"
      }
    />
  );
};

export const ReportList = () => {
  const t = useT();
  const { tillId, sessionId } = useParams();

  const { data: tills } = useTills();
  const tillName = tills?.find((till) => till.id === tillId)?.name;

  const { data: report, status: reportStatus } = useSalesReport(sessionId);

  if (reportStatus !== "success") {
    return <ReportStatus status={reportStatus} />;
  }

  const receiptReport = report.receipt;

  return (
    <div className="mb-5">
      <TitleText
        title={t("Sales overview - {till}", { till: tillName ?? "" })}
      />

      {receiptReport.totalSales.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-sale`}
          title={t("Total sales")}
          value={value}
        />
      ))}

      {receiptReport.averageSales.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-avg`}
          title={t("Average")}
          value={value}
        />
      ))}
      {receiptReport.standardVAT.values.map(({ currency, values: vats }) =>
        vats.map(({ percentage, value: vat }) => (
          <InfoTextWithPriceFormat
            currency={currency}
            key={`${currency}-${percentage}-vat`}
            percentage={percentage}
            title={t("VAT")}
            value={vat}
          />
        ))
      )}

      <InfoText
        title={t("Products sold")}
        value={receiptReport.unitSold.value}
      />

      {receiptReport.totalProductsSales.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-products-sales`}
          title={t("Products sales")}
          value={value}
        />
      ))}

      {receiptReport.refund.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-refund`}
          title={t("Refunds")}
          value={value}
        />
      ))}

      <InfoText
        title={t("Nr. of refunds")}
        value={receiptReport.refundNumber.value}
      />

      {receiptReport.cancelled.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-cancelled`}
          title={t("Cancelled orders")}
          value={value}
        />
      ))}

      <InfoText
        title={t("Nr. of cancelled orders")}
        value={receiptReport.cancelledNumber.value}
      />
      <InfoText
        title={t("Nr. of transactions")}
        value={receiptReport.totalOrders.value}
      />

      {receiptReport.discounts?.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-discount`}
          title={t("Discounts")}
          value={value}
        />
      ))}

      <InfoText
        title={t("Nr. of discounts")}
        value={receiptReport.discountNumber?.value}
      />

      <TitleText className="mt-3" title={t("Payment methods")} />

      {mapNestedValues(receiptReport.sections, ["paymentsMethods", "total"])}

      {mapNestedValues(receiptReport.sections, ["paymentsMethods", "card"])}

      {mapNestedValues(receiptReport.sections, [
        "paymentsMethods",
        "mobilepay",
      ])}

      {mapNestedValues(receiptReport.sections, ["paymentsMethods", "swish"])}

      {mapNestedValues(receiptReport.sections, ["paymentsMethods", "cash"])}

      {mapNestedValues(receiptReport.sections, ["paymentsMethods", "billing"])}

      <TitleText className="mt-3" title={t("Sales by categories")} />
      {Object.values(receiptReport.sections.categories).map((c) => {
        if (typeof c === "string") return null;
        return c.values.map((v) => (
          <div className="mb-2" key={`${v.currency}-avg`}>
            <SubtitleText title={`${c.label} (${v.currency ?? ""})`} />

            <InfoTextWithPriceFormat
              currency={v.currency}
              title={t("Total sales")}
              value={v.value}
            />
            {v.unitSold ? (
              <InfoText title={t("Units sold")} value={v.unitSold} />
            ) : null}
          </div>
        ));
      })}

      <TitleText className="mt-3" title={t("Sales per users")} />
      {Object.values(receiptReport.sections.profiles).map((c) => {
        if (typeof c === "string") return null;
        return c.values.map((v) => (
          <div className="mb-2" key={`${v.currency}-avg`}>
            <SubtitleText title={`${c.label} (${v.currency ?? ""})`} />

            <InfoTextWithPriceFormat
              currency={v.currency}
              title={t("Total sales")}
              value={v.value}
            />
            {v.unitSold ? (
              <InfoText title={t("Units sold")} value={v.unitSold} />
            ) : null}
          </div>
        ));
      })}
    </div>
  );
};

export function mapNestedValues(
  sections: ReceiptReport["sections"],
  props: [
    Exclude<keyof ReceiptReport["sections"], "categories">,
    PaymentMethods,
  ]
) {
  const field = sections[props[0]][props[1]];

  if (typeof field === "string") return null;

  if (!(typeof field === "object" && "values" in field)) return null;

  return (
    <>
      {field.values.map((v) => (
        <InfoTextWithPriceFormat
          currency={v.currency}
          key={`${v.currency}-${field.label}`}
          title={field.label}
          value={v.value}
        />
      ))}
    </>
  );
}
