import type { OrderLines } from "@kanpla/api-contract/dist/libs/types/src";

import type { OrderPersonal } from "@repo/types";
import type { OrderLinesToRefund } from "~/hooks/queries";

import { refundStore } from "./store";

import type { RefundStore } from ".";

function setRefundMethod(refundMethod: RefundStore["refundMethod"]) {
  refundStore.setState((state) => {
    state.refundMethod = refundMethod;
  });
}

type SetOrderLinesArgs = {
  order: Pick<
    OrderPersonal,
    | "status"
    | "id"
    | "currency"
    | "paidWithSaldoUnit"
    | "payments"
    | "payouts"
    | "discounts"
  > & {
    totalPriceUnit: number;
  } & {
    orderLines: OrderLines;
  };
  formattedOrderLines: {
    name: string;
    orderLineId?: string;
    id: string;
    formattedPrice: string;
  }[];
  selectedOrderLines: string[];
};

function setOrderLines({
  order,
  selectedOrderLines,
  formattedOrderLines,
}: SetOrderLinesArgs) {
  refundStore.setState((state) => {
    state.selectedOrderLines = selectedOrderLines.reduce(
      (acc: OrderLinesToRefund[], id) => {
        const line = formattedOrderLines.find((o) => o.id === id);
        if (!line) return acc;
        if (!line.orderLineId) return acc;

        const orderLineInOrder = order.orderLines.find(
          (o) => o.orderLineId === line.orderLineId
        );

        if (!orderLineInOrder) return acc;

        const discount = order.discounts?.find(
          (discount) => discount.items[0].orderLineId === line.orderLineId
        );
        const unitDiscountedAmount = discount?.unitDiscountedAmount ?? 0;

        if (
          !acc.some((orderLine) => orderLine.orderLineId === line.orderLineId)
        ) {
          const unitPrice = unitDiscountedAmount
            ? // if there is a discount, we need to calculate the unit price with the discount
              orderLineInOrder.unitPrice * (1 + orderLineInOrder.vatRate) -
              unitDiscountedAmount / orderLineInOrder.amount
            : // if there is no discount, we can just use the unit price from the orderLine
              orderLineInOrder.unitPrice * (1 + orderLineInOrder.vatRate);

          return [
            ...acc,
            {
              orderLineId: line.orderLineId,
              amount: 1,
              unitPrice,
            },
          ];
        }
        return acc.map((orderLine) => {
          if (orderLine.orderLineId !== line.orderLineId) return orderLine;

          return {
            ...orderLine,
            amount: orderLine.amount + 1,
          };
        });
      },
      []
    );
  });
}

const refundActions = {
  setRefundMethod,
  setOrderLines,
};

export { refundActions };
