import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Profile } from "@repo/types";

import { Dialog, type DialogProps } from "../Dialog";

import { ProfilePin } from "./ProfilePin";

type DialogPinProps = Partial<DialogProps> & {
  profile: Pick<Profile, "id" | "name">;
};
export const DialogPin = ({
  profile: { name, id },
  ...dialogProps
}: DialogPinProps) => {
  return (
    <Dialog className="px-4 py-6" size="md" {...dialogProps}>
      <div className="flex flex-col items-center gap-2">
        <FontAwesomeIcon icon={faUser} size="lg" />
        <h2 className="text-sm font-bold">{name}</h2>
      </div>

      <ProfilePin profileId={id} />
    </Dialog>
  );
};
