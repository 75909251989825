import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";

import { Dialog, type DialogProps } from "./Dialog";
import { Button } from "./Button";

type DialogConfirmProps = Partial<DialogProps> & {
  title?: string;
  onConfirm: () => void;
};

export const DialogConfirm = ({
  children,
  onConfirm,
  title,
  ...props
}: DialogConfirmProps) => {
  return (
    <Dialog size="sm" {...props}>
      <div className="flex flex-col items-center p-8">
        <div className="mb-6 flex size-8 items-center justify-center rounded-full bg-primary-main text-primary-contrast">
          <FontAwesomeIcon className="h-1/2" icon={faCheck} />
        </div>

        {title ? (
          <p className="text-center text-xl font-semibold">
            <T _str={title} />
          </p>
        ) : null}

        {children}

        <div className="mt-6 flex justify-end gap-3">
          {props.onOutsideClick ? (
            <Button onClick={props.onOutsideClick} variant="danger">
              <T _str="Cancel" />
            </Button>
          ) : null}

          <Button onClick={onConfirm} variant="primary">
            <T _str="Confirm" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
