import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { forwardRef } from "react";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

import { T, useT } from "@repo/transifex";
import type { Selector } from "@repo/types";

import type { InvoiceStepSelectorsType } from "../route";

type StepSelectorsProps = {
  selectors: Selector["options"];
};

export const StepSelectors = ({ selectors }: StepSelectorsProps) => {
  const t = useT();

  const {
    register,
    formState: { errors },
  } = useFormContext<InvoiceStepSelectorsType>();

  if (!selectors.length) return <T _str="No results found" />;

  return (
    <div className="flex w-full flex-col items-center gap-3">
      <div className="flex w-full flex-wrap gap-2">
        {selectors.map((selector) => (
          <SelectorRadioButton
            key={selector.name}
            value={selector.name}
            {...register("selector", {
              required: t("This selection is required"),
            })}
          />
        ))}
      </div>
      {errors.selector ? (
        <span className="text-danger-main">{errors.selector.message}</span>
      ) : null}
    </div>
  );
};

type SelectorRadioProps = {
  value: string;
} & JSX.IntrinsicElements["input"];

const SelectorRadioButton = forwardRef<HTMLInputElement, SelectorRadioProps>(
  ({ value, ...props }, ref) => {
    return (
      <label className="h-20 min-w-[20%] max-w-full grow cursor-pointer font-medium">
        <input
          className="peer hidden"
          ref={ref}
          type="radio"
          value={value}
          {...props}
        />
        <span className="flex size-full items-center justify-center gap-2 rounded-md border border-info-backdrop bg-background-primary px-4 peer-checked:bg-info-main peer-checked:text-primary-contrast peer-checked:*:block">
          <FontAwesomeIcon className="hidden" icon={faCheckCircle} />
          {value}
        </span>
      </label>
    );
  }
);

SelectorRadioButton.displayName = "SelectorRadioButton";
