import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { T } from "@repo/transifex";
import { Button, DialogError } from "~/components";
import { employeeActions } from "~/providers/store/employee";
import { useAppDispatch, useCountDownState } from "~/providers/app";
import { useCountdown } from "~/hooks";

export const EmployeeCardErrorPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useCountdown();
  const { count } = useCountDownState();

  const handleClose = () => {
    employeeActions.reset();
    navigate("/pos");
    dispatch({ type: "COUNTDOWN_RESET" });
  };

  useEffect(() => {
    dispatch({ type: "COUNTDOWN_START", payload: 5 });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dispatch is optimized
  }, []);

  useEffect(() => {
    if (count === 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- handleClose is optimized
  }, [count]);

  return (
    <DialogError
      backdropVariant="error"
      error={<T _str="You don't have enough balance" />}
      hideSupport
      onOutsideClick={handleClose}
      size="md"
    >
      <p className="text-center">
        <T _str="Please top up you balance and try again. The current order has been cancelled" />
      </p>
      <Button className="w-full" onClick={handleClose} variant="danger">
        <T _str="Close" />
      </Button>
    </DialogError>
  );
};
