import { useMemo, useState } from "react";
import moment from "moment-timezone";

import { t } from "@repo/transifex";

export const useWeekFilter = () => {
  const [week, setWeek] = useState(moment().isoWeek());

  return useMemo(() => {
    const isCurrent = week === moment().isoWeek();

    const start = moment().isoWeek(week).startOf("isoWeek");
    const startFormat = start.format("MMM DD, YYYY");

    const end = moment().isoWeek(week).endOf("isoWeek");
    const endFormat = end.format("MMM DD, YYYY");

    return {
      label: isCurrent ? t("This week") : `${startFormat} - ${endFormat}`,
      start: start.unix(),
      end: end.unix(),
      handleNext: () => {
        setWeek((prev) => prev + 1);
      },
      handlePrev: () => {
        setWeek((prev) => prev - 1);
      },
    };
  }, [week]);
};

export const useDayFilter = (timezone?: string) => {
  const [day, setDay] = useState(moment().isoWeekday());

  return useMemo(() => {
    const baseMoment = timezone ? moment.tz(timezone) : moment();
    const isCurrent = day === baseMoment.isoWeekday();

    const start = baseMoment.clone().isoWeekday(day).startOf("day");
    const end = baseMoment.clone().isoWeekday(day).endOf("day");

    return {
      label: isCurrent ? t("Today") : start.format("MMM DD, YYYY"),
      start: start.unix(),
      end: end.unix(),
      handleNext: () => {
        setDay((prev) => prev + 1);
      },
      handlePrev: () => {
        setDay((prev) => prev - 1);
      },
    };
  }, [day, timezone]);
};
