import {
  faCircleCheck,
  faEnvelope,
  faPrint,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useSearchParams } from "react-router-dom";

import { priceFormatter } from "@repo/system";
import { T } from "@repo/transifex";
import {
  useCurrentTill,
  useOrder,
  usePrintOrderReceipt,
} from "~/hooks/queries";
import {
  useAppContext,
  useAppDispatch,
  useBasketProducts,
  useCashDrawerStatus,
  useCountDownState,
} from "~/providers/app";
import { useAuth } from "~/providers/store/auth";

import { Button } from "../Button";
import { Dialog } from "../Dialog";

type DialogPaymentSuccessProps = {
  orderId: string;
  change?: number;
  isAttended: boolean;
  onClose: () => void;
};

export const DialogPaymentSuccess = ({
  onClose,
  orderId,
  isAttended,
  change = 0,
}: DialogPaymentSuccessProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");

  const {
    meta: { currency },
  } = useBasketProducts();
  const { count } = useCountDownState();
  const { data: till } = useCurrentTill();
  const { locale } = useAuth();
  const { data: order, status } = useOrder(orderId);

  const {
    hardware: { printer },
  } = useAppContext();

  const cashDrawerStatus = useCashDrawerStatus();

  const { mutate: createReceipt } = usePrintOrderReceipt();

  const canCloseDialog =
    !till?.moduleConfig.requiredCashDrawerClosing ||
    cashDrawerStatus === "closed";

  if (status !== "success" || !order) return null;

  return (
    <Dialog
      backdropVariant="success"
      onOutsideClick={() => {
        canCloseDialog && onClose();
      }}
      size="md"
    >
      <div className="flex flex-col gap-y-10">
        <div className="flex flex-col gap-y-10 px-5 pt-8">
          <div className="flex flex-col items-center justify-center gap-y-5 text-primary-main">
            <FontAwesomeIcon className="h-11" icon={faCircleCheck} />

            <span className="text-3xl font-semibold">
              {order?.totalPriceUnit >= 0 ? (
                <T _str="Payment completed" />
              ) : (
                <T _str="Refund completed" />
              )}
            </span>
          </div>

          {change > 0 && (
            <p className="mb-4 text-center text-xl text-text-primary">
              <T
                _str="Change money {change}"
                change={priceFormatter({
                  value: change * 100,
                  currency,
                  locale,
                })}
              />
            </p>
          )}

          {order.totalPriceUnit < 0 && (
            <p className="mb-4 text-center text-xl text-text-primary">
              <T
                _str="Refund {change}"
                change={priceFormatter({
                  value: -order.totalPriceUnit,
                  currency,
                  locale,
                })}
              />
            </p>
          )}
        </div>

        <div className="px-5 pb-5">
          <div className="flex flex-col gap-y-5">
            <Button
              className="text-lg"
              disabled={!canCloseDialog}
              hidden={!printer}
              icon={faPrint}
              onClick={() => {
                createReceipt({ orderId });
                navigate({
                  pathname: "print",
                  search: `?orderId=${orderId}`,
                });
                dispatch({ type: "COUNTDOWN_RESET" });
              }}
              size="large"
              variant="secondary"
            >
              <T _str="Print receipt" />
            </Button>

            <Button
              className="text-lg"
              disabled={!canCloseDialog}
              icon={faEnvelope}
              onClick={() => {
                const query = serviceId ? `&serviceId=${serviceId}` : "";
                navigate({
                  pathname: "email",
                  search: `?orderId=${orderId}${query}`,
                });
                dispatch({ type: "COUNTDOWN_RESET" });
              }}
              size="large"
              variant="secondary"
            >
              <T _str="Email receipt" />
            </Button>

            <Button
              className="text-lg"
              disabled={!canCloseDialog}
              onClick={onClose}
              size="large"
              variant="primary"
            >
              {isAttended ? (
                <T _str="Close" />
              ) : (
                <T _str="Close ({count})" count={count} />
              )}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
