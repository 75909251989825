import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import moment from "moment-timezone";

import { T, useT } from "@repo/transifex";
import type { Till, TillSession } from "@repo/types";
import { useSession, useSessionOrders, useTill } from "~/hooks/queries";
import { Report } from "~/components/Report";
import {
  DialogError,
  DialogLoading,
  HeaderSeparator,
  SettingsLayout,
  TillStatusButton,
} from "~/components";

import {
  closeCashBalanceAtom,
  HeaderButtonCloseSession,
} from "./HeaderButtonCloseSession";

type LayoutProps = {
  children: React.ReactNode;
  subtitle?: string;
  status?: "open" | "closed";
  tillId?: Till["id"];
  startedAt?: TillSession["startedAt"];
};

const Layout = ({
  children,
  subtitle,
  status,
  tillId,
  startedAt,
}: LayoutProps) => {
  const t = useT();

  return (
    <SettingsLayout
      action={<HeaderButtonCloseSession />}
      backUrl={`/settings/tills/${tillId}`}
      headerChildren={
        <>
          {status ? <TillStatusButton status={status} /> : null}
          <HeaderSeparator />
          <p className="text-xs">
            {startedAt
              ? moment.unix(startedAt).format("D MMMM YY h:mm a")
              : null}
          </p>
        </>
      }
      subtitle={subtitle}
      title={t("Register Management")}
    >
      {children}
    </SettingsLayout>
  );
};

const TillSessionClosePage = () => {
  const { tillId, sessionId } = useParams();

  const { data: till, status: tillStatus } = useTill(tillId);
  const { data: tillSession, status: tillSessionStatus } =
    useSession(sessionId);
  const { data, status } = useSessionOrders(sessionId);

  const [closeCashBalance, setCloseCashBalance] = useAtom(closeCashBalanceAtom);

  if (
    status === "pending" ||
    tillStatus === "pending" ||
    tillSessionStatus === "pending"
  )
    return (
      <Layout>
        <DialogLoading />
      </Layout>
    );
  if (
    status === "error" ||
    tillStatus === "error" ||
    tillSessionStatus === "error"
  )
    return (
      <Layout>
        <DialogError />
      </Layout>
    );

  const startedCashBalance =
    tillSession.startedFlow === "manual"
      ? tillSession.startedMeta.cashBalance?.[data.currency] || 0
      : 0;
  const expected = data.totalCashPriceUnit + startedCashBalance;

  return (
    <Layout
      startedAt={tillSession.startedAt}
      status={till.sessionId ? "open" : "closed"}
      subtitle={till.name}
      tillId={tillId}
    >
      {till.paymentProviders.includes("cash") ? (
        <Report
          cashBalance={closeCashBalance}
          currency={data.currency}
          expected={expected / 100}
          setCashBalance={setCloseCashBalance}
          title="Cash"
        />
      ) : (
        <T _str="Register can be closed" />
      )}
    </Layout>
  );
};

export { TillSessionClosePage };
