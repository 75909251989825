import { Outlet, useParams } from "react-router-dom";

import { T, useT } from "@repo/transifex";
import type { Till } from "@repo/types";
import { useSessionOrders, useTill } from "~/hooks/queries";
import {
  DialogError,
  DialogLoading,
  HeaderSeparator,
  SettingsLayout,
  TableTransactions,
  TillStatusButton,
} from "~/components";

type LayoutProps = {
  children: React.ReactNode;
  subtitle?: string;
  status?: "open" | "closed";
  tillId?: Till["id"];
};

const Layout = ({ children, subtitle, status, tillId }: LayoutProps) => {
  const t = useT();

  return (
    <SettingsLayout
      backUrl={`/settings/tills/${tillId}`}
      className="pb-0"
      headerChildren={
        <>
          {status ? <TillStatusButton status={status} /> : null}
          <HeaderSeparator />
          <p className="text-2xl font-medium text-text-primary">
            <T _str="Transactions" />
          </p>
        </>
      }
      subtitle={subtitle}
      title={t("Register Management")}
    >
      {children}
    </SettingsLayout>
  );
};

const TillSessionTransactionPage = () => {
  const { tillId, sessionId } = useParams();

  const { data: till, status: tillStatus } = useTill(tillId);
  const { data: orders, status } = useSessionOrders(sessionId);

  if (tillStatus === "pending")
    return (
      <Layout>
        <DialogLoading />
      </Layout>
    );

  if (tillStatus === "error")
    return (
      <Layout>
        <DialogError />
      </Layout>
    );

  return (
    <Layout
      status={till.sessionId ? "open" : "closed"}
      subtitle={till.name}
      tillId={tillId}
    >
      <TableTransactions
        data={status === "success" ? orders.orders : undefined}
        status={status}
      />
      <Outlet />
    </Layout>
  );
};

export { TillSessionTransactionPage };
