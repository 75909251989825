import { useNavigate } from "react-router-dom";

import { T } from "@repo/transifex";
import { useTerminals, useUpdateTill } from "~/hooks/queries";
import { Dialog, DialogError, DialogLoading } from "~/components";

const TerminalsPage = () => {
  const { data: terminals, status } = useTerminals();

  const { mutate: updateTill } = useUpdateTill();

  const navigate = useNavigate();

  if (status === "pending") return <DialogLoading backUrl=".." />;

  if (status === "error" || !terminals.length)
    return <DialogError backUrl=".." error="No terminals found" />;

  return (
    <Dialog backUrl=".." size="md">
      <div className="flex w-full flex-col p-8">
        <p className="mx-auto mb-16 text-3xl">
          <T _str="Choose device" />
        </p>

        {terminals.map((terminal) => (
          <button
            className="border-y p-4 text-left hover:bg-gray-100"
            key={terminal.id}
            onClick={() => {
              updateTill({ terminalId: terminal.id });
              navigate("..");
            }}
            type="button"
          >
            {terminal.id}
          </button>
        ))}
      </div>
    </Dialog>
  );
};

export { TerminalsPage };
