import { faX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T, useT } from "@repo/transifex";
import { Dialog } from "~/components";
import { useDeviceData } from "~/providers/app";
import { useAuth } from "~/providers/store/auth";

type SupportModalProps = {
  onClose: () => void;
};

export const InfoModal = ({ onClose }: SupportModalProps) => {
  const t = useT();

  const { userId } = useAuth();
  const { deviceId, os, osVersion, tillId } = useDeviceData();

  return (
    <Dialog onOutsideClick={onClose} size="md">
      <div className="flex flex-col items-center p-3 pt-8">
        <button
          className="mb-6 flex items-center gap-2.5"
          onClick={onClose}
          type="button"
        >
          <FontAwesomeIcon icon={faX} />
          <T _str="Close" />
        </button>

        <p className="mb-4 text-xl font-semibold">
          <T _str="App info" />
        </p>

        <div className="flex w-full flex-col gap-2">
          <InfoItem label={t("Device ID")} value={deviceId} />
          <InfoItem label={t("Device OS")} value={os} />
          <InfoItem label={t("OS version")} value={osVersion} />
          {/* TODO: App version */}
          <InfoItem label={t("Till ID")} value={tillId} />
          <InfoItem label={t("User")} value={userId} />
        </div>
      </div>
    </Dialog>
  );
};

type InfoItemProps = {
  label: string;
  value: string | null;
};

const InfoItem = ({ label, value }: InfoItemProps) => {
  return (
    <div className="w-full rounded-md bg-background-secondary p-2 text-center">
      <p>
        <span className="mr-3 text-text-disabled">{label}</span>
        {value ?? "-"}
      </p>
    </div>
  );
};
