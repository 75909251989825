import { useState } from "react";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";

import { T } from "@repo/transifex";
import { useSalesReport } from "~/hooks/queries";
import { Button } from "~/components";

import { DialogSendReportEmail } from "./DialogSendReportEmail";

export const SendReportButton = ({ sessionId }: { sessionId: string }) => {
  const [open, setOpen] = useState(false);

  const { data, status: reportStatus } = useSalesReport(sessionId);

  if (reportStatus === "error") return null;

  return (
    <>
      <Button
        className="w-full"
        icon={faEnvelope}
        loading={reportStatus === "pending"}
        onClick={() => {
          setOpen(true);
        }}
        size="large"
        variant="primary-light"
      >
        <T _str="Send report" />
      </Button>

      {data?.report ? (
        <DialogSendReportEmail
          onOutsideClick={() => {
            setOpen(false);
          }}
          open={open}
          sessionId={sessionId}
        />
      ) : null}
    </>
  );
};
