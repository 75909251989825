import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { T, useT } from "@repo/transifex";
import {
  DialogError,
  DialogLoading,
  SettingsLayout,
  TillStatusButton,
  Time,
} from "~/components";
import { useWeekFilter } from "~/hooks";
import { useSessions, useTill } from "~/hooks/queries";

import { HeaderButton } from "./HeaderButton";
import { SessionCard, SessionCardSkeleton } from "./SessionCard";

type SessionsListProps = {
  start: number;
  end: number;
};

const SessionsList = ({ start, end }: SessionsListProps) => {
  const params = useParams();

  const { data: sessions, status } = useSessions({
    tillId: params.tillId ?? null,
    start,
    end,
  });

  if (status === "pending")
    return (
      <>
        {Array.from({ length: 6 }).map(() => (
          <SessionCardSkeleton key={uuidv4()} />
        ))}
      </>
    );

  if (status === "error") {
    return (
      <div className="flex size-full items-center justify-center">
        <p className="text-lg text-text-secondary">
          <T _str="Could not fetch sessions" />
        </p>
      </div>
    );
  }

  if (!sessions.length) {
    return (
      <div className="flex size-full items-center justify-center">
        <p className="text-lg text-text-secondary">
          <T _str="No sessions found" />
        </p>
      </div>
    );
  }

  return sessions.map(({ id, status, startedAt, endedAt }) => {
    return (
      <SessionCard
        endedAt={endedAt}
        id={id}
        key={id}
        startedAt={startedAt}
        status={status}
      />
    );
  });
};

type LayoutProps = {
  children: React.ReactNode;
  subtitle?: string;
  status?: "open" | "closed";
};

const Layout = ({ children, subtitle, status }: LayoutProps) => {
  const t = useT();

  return (
    <SettingsLayout
      action={<HeaderButton />}
      backUrl="/settings/tills"
      subtitle={subtitle}
      title={t("Register Management")}
      {...(status && { headerChildren: <TillStatusButton status={status} /> })}
      className="pb-0"
    >
      {children}
    </SettingsLayout>
  );
};

const TillPage = () => {
  const { tillId } = useParams();

  const { start, end, label, handleNext, handlePrev } = useWeekFilter();
  const { data: till, status } = useTill(tillId);

  if (status === "pending")
    return (
      <Layout>
        <DialogLoading />
      </Layout>
    );

  if (status === "error")
    return (
      <Layout>
        <DialogError />
      </Layout>
    );

  return (
    <Layout status={till.sessionId ? "open" : "closed"} subtitle={till.name}>
      <div className="grid h-full grid-rows-[auto_1fr]">
        <div className="flex justify-end">
          <Time label={label} onNext={handleNext} onPrev={handlePrev} />
        </div>

        <div className="mt-5 flex h-[calc(100%-theme(spacing.5))] flex-col gap-y-5 overflow-auto pb-5 scrollbar-hide">
          <SessionsList end={end} start={start} />
        </div>
      </div>
    </Layout>
  );
};

export { TillPage };
