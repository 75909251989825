import type { StepVariant } from "@repo/types";
import { Collapse } from "~/components";

import { VariantChoices } from "./Choices";
import { VariantHeader } from "./VariantHeader";

type VariantProps = {
  variant: StepVariant;
  isOnlyOneStep: boolean;
};

export const Variant = ({ variant, isOnlyOneStep }: VariantProps) => {
  if (variant.isCompleted && !isOnlyOneStep) {
    return (
      <Collapse
        label={
          <VariantHeader
            choices={variant.choices}
            isValid={variant.isValid}
            minimum={variant.minimum}
            name={variant.name}
          />
        }
        stepId={variant.id}
      >
        <VariantChoices variant={variant} />
      </Collapse>
    );
  }

  if (variant.isCurrent || isOnlyOneStep) {
    return (
      <div className="flex flex-col">
        <VariantHeader
          choices={variant.choices}
          isValid={variant.isValid}
          minimum={variant.minimum}
          name={variant.name}
        />

        <VariantChoices variant={variant} />
      </div>
    );
  }

  return null;
};
