import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { T } from "@repo/transifex";
import { Button, Dialog, DialogError, DialogLoading } from "~/components";
import { useSendWebEvent } from "~/hooks";
import { useAppContext, useAppDispatch } from "~/providers/app";
import type { Scale, ScaleData } from "~/types/hardware";

const isScaleWithModel = (
  scale: Scale
): scale is { model: string; scale: ScaleData } =>
  "model" in scale && "scale" in scale;

const isFTDI = (vendorId: string) => {
  return String(vendorId) === "1027";
};

const ScaleButton = ({
  key,
  scale,
  onScaleSelect,
}: {
  key: string;
  scale: Scale;
  onScaleSelect: (scale: Scale) => void;
}) => {
  let vendorId;
  if (isScaleWithModel(scale)) {
    vendorId = scale.scale.vendorId;
  } else if (isFTDI(scale.vendorId)) {
    vendorId = "FTDI";
  } else {
    vendorId = scale.vendorId;
  }

  return (
    <button
      className="border-y p-4 text-left hover:bg-gray-100"
      key={key}
      onClick={() => { onScaleSelect(scale); }}
      type="button"
      >
      {isScaleWithModel(scale) ? scale.scale.deviceId : scale.deviceId} -{" "}
      {vendorId} -{" "}
      {isScaleWithModel(scale) ? scale.scale.productId : scale.productId}
    </button>
  );
};

const ScalesPage = () => {
  const {
    hardware: {
      scales: {
        discovery: { devices: scales, status },
      },
    },
  } = useAppContext();

  const dispatch = useAppDispatch();
  const sendWebEvent = useSendWebEvent();
  const navigate = useNavigate();

  const [selectedScale, setSelectedScale] = useState<Scale | undefined>();

  const handleDiscoveryReset = () => {
    dispatch({ type: "SCALES_DISCOVER_RESET" });
    setSelectedScale(undefined);
  };

  const handleScaleConnect = (scale: Scale, model: "pdn" | "pd2") => {
    sendWebEvent({
      type: "SCALE_SELECT_REQUEST",
      payload: { scale, model },
    });
    handleDiscoveryReset();
    navigate("..");
  };

  if (status === "loading" || status === "idle")
    return <DialogLoading backUrl=".." onOutsideClick={handleDiscoveryReset} />;

  if (status === "error" || !scales.length)
    return (
      <DialogError
        backUrl=".."
        error="No Scales found"
        onOutsideClick={handleDiscoveryReset}
      />
    );

  if (selectedScale) {
    return (
      <Dialog backUrl=".." onOutsideClick={handleDiscoveryReset} size="md">
        <div className="flex w-full flex-col gap-6 p-8 text-center">
          <p className="text-2xl font-bold">Please choose your scale model</p>

          <div className="flex w-full flex-wrap gap-4">
            <Button
              className="min-w-[150px] flex-1"
              onClick={() => {
                handleScaleConnect(selectedScale, "pd2");
              }}
              variant="secondary"
            >
              CAS PD-II
            </Button>

            <Button
              className="min-w-[150px] flex-1"
              onClick={() => {
                handleScaleConnect(selectedScale, "pdn");
              }}
              variant="secondary"
            >
              CAS PDN
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog backUrl=".." onOutsideClick={handleDiscoveryReset} size="md">
      <div className="flex w-full flex-col p-8">
        <p className="mx-auto mb-16 text-3xl">
          <T _str="Choose Scale" />
        </p>

        {scales.map((scale) => (
          <ScaleButton
            key={"model" in scale ? scale.scale.deviceId : scale.deviceId}
            onScaleSelect={setSelectedScale}
            scale={scale}
          />
        ))}
      </div>
    </Dialog>
  );
};

export { ScalesPage };
