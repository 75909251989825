import { clsx } from "clsx";
import Svg from "react-inlinesvg";

import { T } from "@repo/transifex";
import { useCurrentSchool, useCurrentTill, useSupplier } from "~/hooks/queries";
import { KanplaLogo } from "~/components";

import { CurveArrow } from "./CurveArrow";

export const WelcomeGuest = () => {
  const { data: school } = useCurrentSchool();
  const { data: till } = useCurrentTill();
  const { data: supplier, status } = useSupplier();

  const isSelectorsScreen =
    till?.type === "self-service" &&
    till.config.selectorSetup === "show-selectors" &&
    Boolean(school?.selector);

  const logo = supplier?.logo;
  const { svg, url } = logo || {};

  return (
    <div className="flex h-full flex-1 flex-col justify-center bg-slate-100">
      {isSelectorsScreen ? (
        <div
          className={clsx(
            "mb-8 flex aspect-square h-40 items-center justify-center self-center"
          )}
        >
          {/** Logo - url */}
          {status === "success" && url ? (
            <div>
              <img alt="logo" src={url} />
            </div>
          ) : null}

          {/** Logo - svg */}
          {status === "success" && !url && svg ? (
            <Svg fill="currentColor" height="100%" src={svg} width="100%" />
          ) : null}

          {/** Default - Kanpla Logo */}
          {status !== "pending" && !svg && !url && <KanplaLogo />}
        </div>
      ) : null}

      <p className="self-center text-5xl">
        <T _str="Welcome!" />
      </p>
      <p className="mx-2 mb-4 self-center text-center text-2xl">
        {isSelectorsScreen ? (
          <T _str="Choose which company you belong to" />
        ) : (
          <T _str="Tap to begin" />
        )}
      </p>

      <div className="px-16">
        <CurveArrow />
      </div>
    </div>
  );
};
