import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { T } from "@repo/transifex";
import { useCurrentTill, usePrintOrderReceipt } from "~/hooks/queries";
import {
  useAppDispatch,
  useCountDownState,
  usePrinterStatus,
} from "~/providers/app";
import {
  Button,
  DialogError,
  DialogLoading,
  DialogSuccess,
} from "~/components";
import { basketActions } from "~/providers/store/basket";
import { employeeActions } from "~/providers/store/employee";

const PosPrintReceiptPage = () => {
  const dispatch = useAppDispatch();
  const { count } = useCountDownState();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const { data: till } = useCurrentTill();
  const isAttended = till?.type === "attended";

  const printerStatus = usePrinterStatus();

  const { mutate: createReceipt } = usePrintOrderReceipt();

  const handleClose = () => {
    basketActions.reset();
    employeeActions.reset();
    dispatch({ type: "PRINTER_PRINT_RECEIPT_RESET" });
    navigate("/pos");
  };

  useEffect(() => {
    if (count === 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on count change
  }, [count]);

  if (printerStatus === "error") {
    return (
      <DialogError
        backdropVariant="error"
        error={
          <span className="font-semibold">
            <T _str="Receipt couldn't be printed" />
          </span>
        }
        hideSupport
        size="md"
      >
        <div className="flex w-full flex-col items-center">
          <p>
            <T _str="Something went wrong." />
          </p>
          <p>
            <T
              _str="Please try again later under {transactions}"
              transactions={
                <b>
                  <T _str="Transactions" />
                </b>
              }
            />
          </p>
        </div>

        <div className="flex w-full flex-col gap-y-5 pt-5">
          {orderId ? (
            <Button
              className="w-full"
              onClick={() => {
                createReceipt({ orderId });
                dispatch({ type: "COUNTDOWN_RESET" });
              }}
              variant="primary"
            >
              <T _str="Try again" />
            </Button>
          ) : null}
          <Button className="w-full" onClick={handleClose} variant="secondary">
            {isAttended ? (
              <T _str="Close" />
            ) : (
              <T _str="Close ({count})" count={count} />
            )}
          </Button>
        </div>
      </DialogError>
    );
  }

  if (printerStatus === "loading") {
    return (
      <DialogLoading
        backdropVariant="success"
        size="md"
        title={
          <span className="font-semibold">
            <T _str="Please wait" />
          </span>
        }
      >
        <T _str="The receipt is being printed..." />
      </DialogLoading>
    );
  }

  if (printerStatus === "success") {
    return (
      <DialogSuccess backdropVariant="success" size="md">
        <p className="text-center text-xl font-semibold text-primary-main">
          <T _str="Receipt has been printed" />
        </p>

        <div className="w-full pt-5">
          <Button className="w-full" onClick={handleClose} variant="primary">
            {isAttended ? (
              <T _str="Close" />
            ) : (
              <T _str="Close ({count})" count={count} />
            )}
          </Button>
        </div>
      </DialogSuccess>
    );
  }

  return (
    <DialogLoading
      backdropVariant="success"
      onOutsideClick={handleClose}
      size="md"
      title={
        <span className="font-semibold">
          <T _str="Please wait" />
        </span>
      }
    >
      <T _str="The print request was sent to the printer" />
    </DialogLoading>
  );
};

export { PosPrintReceiptPage };
