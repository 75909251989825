import { useState } from "react";

import { round } from "@repo/system";
import type { Currency } from "@repo/types";

import { Button } from "..";

import { InputWithNumPad } from "./InputWithNumPad";

export type DiscountTypes = "currency" | "percentage";

type DiscountTypesButtonProps = {
  handleChangeType: (type: DiscountTypes) => void;
  currency: Currency;
  type: DiscountTypes;
};

const DiscountTypesButton = ({
  currency,
  handleChangeType,
  type,
}: DiscountTypesButtonProps) => {
  const [discountType, setDiscountType] = useState<DiscountTypes>(type);

  return (
    <div className="flex w-28">
      <Button
        className="flex-1 rounded-r-none"
        onClick={(e) => {
          e.stopPropagation();
          setDiscountType("percentage");
          handleChangeType("percentage");
        }}
        size="small"
        variant={discountType === "currency" ? "info-inverted" : "info"}
      >
        %
      </Button>
      <Button
        className="flex-1 rounded-l-none"
        onClick={(e) => {
          e.stopPropagation();
          setDiscountType("currency");
          handleChangeType("currency");
        }}
        size="small"
        variant={discountType === "percentage" ? "info-inverted" : "info"}
      >
        {currency}
      </Button>
    </div>
  );
};

type DiscountInputProps = {
  handleChangeDiscount: (change: number) => void;
  value: number | undefined;
  type: DiscountTypes;
} & DiscountTypesButtonProps;

export const DiscountInput = ({
  handleChangeDiscount,
  handleChangeType,
  currency,
  value,
  type,
}: DiscountInputProps) => {
  return (
    <InputWithNumPad
      amount={value ? round(value, 2) : null}
      className="items-start !justify-start"
      onChange={(value) => {
        handleChangeDiscount(value);
      }}
      placeholder={0}
      suffix={
        <DiscountTypesButton
          currency={currency}
          handleChangeType={handleChangeType}
          type={type}
        />
      }
      variant={type}
    />
  );
};
