import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";

import { T, useT } from "@repo/transifex";
import { Button } from "~/components";
import { useSendWebEvent } from "~/hooks";
import { useOrderCancel, usePaymentCreate } from "~/hooks/queries";
import { useCashDrawerStatus, useDeviceData } from "~/providers/app";

type CashResultProps = {
  orderId: string | null;
  change: number;
  loadingOrder: boolean;
  received: number;
};

export const CalculatorButtons = ({
  orderId,
  change,
  loadingOrder,
  received,
}: CashResultProps) => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const t = useT();

  const sendWebEvent = useSendWebEvent();
  const { os, osVersion } = useDeviceData();
  const { mutate: payOrder, status } = usePaymentCreate();
  const { mutate: cancelOrder, status: cancelStatus } = useOrderCancel();

  const cashDrawerStatus = useCashDrawerStatus();
  const isPositiveChange = change >= 0;

  const loading =
    status === "pending" || loadingOrder || cancelStatus === "pending";

  return (
    <>
      <Button
        disabled={loading}
        onClick={() => {
          if (orderId) {
            cancelOrder(orderId);
            navigate("..");
          }
        }}
        size="xl"
        variant="danger"
      >
        <T _str="Cancel" />
      </Button>
      <Button
        className="col-span-2"
        disabled={
          cashDrawerStatus !== "closed" || loadingOrder || received === 0
        }
        icon={loading ? faSpinner : undefined}
        loading={loading}
        loadingText={t("Processing order")}
        onClick={() => {
          if (orderId && os && osVersion)
            payOrder(
              {
                change: isPositiveChange ? change : 0, // if change is negative -> split payment
                orderId,
                paymentProvider: "cash",
                deviceInfo: {
                  os,
                  osVersion,
                },
                unitCashReceived: received * 100,
              },
              {
                onSuccess: () => {
                  sendWebEvent({ type: "CASH_DRAWER_OPEN_REQUEST" });

                  if (!isPositiveChange) {
                    navigate("..");
                    setSearchParams({
                      orderId,
                    });
                  }
                },
              }
            );
        }}
        size="xl"
      >
        {isPositiveChange ? <T _str="Confirm" /> : <T _str="Split payment" />}
      </Button>
    </>
  );
};
