import { useState } from "react";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { clsx } from "clsx";

import { T } from "@repo/transifex";
import { useAppDispatch } from "~/providers/app";
import { basketActions } from "~/providers/store/basket";
import { useAuth } from "~/providers/store/auth";
import { useProfiles } from "~/hooks/queries";

import { Button } from "./Button";
import { Dialog } from "./Dialog";
import { ProfilePin } from "./Pin";
import { DialogError } from "./DialogError";
import { DialogLoading } from "./DialogLoading";
import { InitialsCircle } from "./InitialsCircle";

export const UnattendedLogoutButton = () => {
  const dispatch = useAppDispatch();

  const [modal, setModal] = useState<"confirm" | "profiles" | null>(null);

  const handleClose = () => {
    dispatch({ type: "COUNTDOWN_START" });
    setModal(null);
  };

  return (
    <>
      <Button
        className="shadow"
        icon={faLock}
        onClick={() => {
          dispatch({ type: "COUNTDOWN_RESET" });
          dispatch({ type: "COUNTDOWN_STOP" });
          setModal("confirm");
        }}
        variant="info-inverted"
      />

      {modal === "confirm" ? (
        <DialogConfirm
          onClose={handleClose}
          onConfirm={() => {
            basketActions.reset();
            setModal("profiles");
          }}
        />
      ) : null}

      {modal === "profiles" ? <DialogProfiles onClose={handleClose} /> : null}
    </>
  );
};

type DialogConfirmProps = {
  onClose: () => void;
  onConfirm: () => void;
};
const DialogConfirm = ({ onClose, onConfirm }: DialogConfirmProps) => {
  return (
    <Dialog onOutsideClick={onClose} size="md">
      <div className="flex flex-col gap-y-10">
        <div className="flex flex-col items-center justify-center gap-y-8 px-5 pt-8">
          <p className="text-3xl font-semibold">
            <T _str="You are about to leave" />
          </p>
          <p>
            <T _str="Are you sure you wish to continue?" />
          </p>
        </div>

        <div className="px-5 pb-5">
          <div className="flex gap-x-5">
            <Button
              className="flex-1"
              onClick={onClose}
              size="large"
              variant="danger"
            >
              <T _str="Cancel" />
            </Button>

            <Button
              className="flex-1"
              onClick={onConfirm}
              size="large"
              variant="primary"
            >
              <T _str="Continue" />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

type DialogProfilesProps = {
  onClose: () => void;
};
const DialogProfiles = ({ onClose }: DialogProfilesProps) => {
  const { profileId } = useAuth();

  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(
    profileId
  );

  const { data, status } = useProfiles();

  if (status === "pending") {
    return <DialogLoading />;
  }

  if (status === "error") {
    return (
      <DialogError
        error={<T _str="Could not load profiles" />}
        onOutsideClick={onClose}
      />
    );
  }

  return (
    <Dialog className="flex max-h-[80%]" onOutsideClick={onClose} size="lg">
      <div className="flex flex-1 flex-col gap-4 overflow-scroll bg-background-secondary p-6">
        <p className="text-lg font-semibold">
          <T _str="Choose user" />
        </p>

        {data
          .sort((a) => (a.id === profileId ? -1 : 1))
          .map(({ name, id }) => {
            return (
              <Button
                className={clsx({
                  "!bg-text-primary": selectedProfileId === id,
                })}
                key={id}
                onClick={() => {
                  setSelectedProfileId(id);
                }}
                variant={selectedProfileId === id ? "primary" : "light"}
              >
                <div className="flex w-full items-center gap-4">
                  <InitialsCircle
                    className={clsx({
                      "!bg-text-primary !text-primary-contrast":
                        selectedProfileId !== id,
                      "!bg-primary-contrast !text-text-primary":
                        selectedProfileId === id,
                    })}
                    name={name}
                  />
                  {name}
                </div>
              </Button>
            );
          })}
      </div>

      <div className="flex flex-1 flex-col items-center gap-4 p-6">
        <T _str="Enter pin" />

        {selectedProfileId ? (
          <ProfilePin profileId={selectedProfileId} />
        ) : null}
      </div>
    </Dialog>
  );
};
