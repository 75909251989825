import { useState } from "react";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";
import { useOrderEmail } from "~/hooks/queries";
import { Button, Dialog, type DialogProps } from "~/components";

import { DialogSuccess } from "../DialogSuccess";

type DialogEmailReceiptProps = Partial<DialogProps> & {
  orderId: string;
};

export const DialogEmailReceipt = ({
  orderId,
  ...dialogProps
}: DialogEmailReceiptProps) => {
  const { mutate: sendEmail, status } = useOrderEmail();

  const [email, setEmail] = useState("");

  if (status === "success")
    return <DialogSuccess {...dialogProps} title="Email sent!" />;

  return (
    <Dialog size="md" {...dialogProps}>
      <div className="flex flex-col items-center justify-center px-8 py-5 text-center">
        <div className="mb-8 flex flex-col gap-3 text-text-secondary">
          <h2 className="text-xl">
            <T _str="Send receipt to email" />
          </h2>
          <p className="text-xs">
            <T _str="Enter email" />
          </p>
        </div>
        <div className="relative flex w-full max-w-[70%] flex-col rounded-md border border-gray-300 px-3 py-2 text-left">
          <label className="text-xs text-text-disabled">
            <T _str="Email" />
          </label>
          <input
            className="border-none text-sm text-text-secondary outline-none"
            name="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="text"
            value={email}
          />
          <FontAwesomeIcon
            className="absolute right-3 top-1/3"
            icon={faEnvelope}
          />
        </div>

        {status === "error" && (
          <div>
            <T _str="Error sending the email, try again" />
          </div>
        )}

        <div className="mt-6 flex gap-3">
          <Button
            onClick={() => dialogProps.onOutsideClick?.()}
            variant="danger-light"
          >
            <T _str="Cancel" />
          </Button>
          <Button
            disabled={!email || status === "pending"}
            loading={status === "pending"}
            onClick={() => {
              if (!orderId) return;
              sendEmail({
                orderId,
                email,
              });
            }}
            variant="primary"
          >
            <T _str="Send" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
