import { clsx } from "clsx";

import { T } from "@repo/transifex";
import type {
  Currency,
  TillSession,
  TillSessionMetaCashBalance,
} from "@repo/types";
import { ReportLayout } from "~/components/Report";

type TillReportProps = Pick<TillSession, "startedMeta" | "endedMeta">;
export const TillCashReport = ({ startedMeta, endedMeta }: TillReportProps) => {
  if (startedMeta === null || endedMeta === null) {
    return null;
  }

  return (
    <ReportLayout title={<T _str="Cash" />}>
      <div className="flex flex-wrap items-center gap-5">
        <div className="flex items-center gap-5">
          <p className="text-text-secondary">
            <T _str="Opening balance" />
          </p>

          <Balance balance={startedMeta.cashBalance} />
        </div>

        <div className="flex items-center gap-5">
          <p className="text-text-secondary">
            <T _str="Expected closing balance" />
          </p>

          <Balance balance={endedMeta.expectedCashBalance} />
        </div>

        <div className="flex items-center gap-5">
          <p className="text-text-secondary">
            <T _str="Closing balance" />
          </p>

          <Balance balance={endedMeta.actualCashBalance} />
        </div>
      </div>

      <div className="flex items-center gap-5">
        <p className="text-text-secondary">
          <T _str="Difference" />
        </p>

        <Difference
          closeCashBalance={endedMeta.actualCashBalance}
          expectedCloseBalance={endedMeta.expectedCashBalance}
        />
      </div>
    </ReportLayout>
  );
};

type BalanceProps = {
  balance: TillSessionMetaCashBalance;
};
const Balance = ({ balance }: BalanceProps) => {
  if (!balance) return <p>-</p>;
  return Object.entries(balance).map(([currency, amount]) => (
    <p key={`open_${amount}_${currency}`}>
      {amount / 100} {currency}
    </p>
  ));
};

type DifferenceProps = {
  expectedCloseBalance: TillSessionMetaCashBalance;
  closeCashBalance: TillSessionMetaCashBalance;
};
const Difference = ({
  expectedCloseBalance,
  closeCashBalance,
}: DifferenceProps) => {
  if (!closeCashBalance || !expectedCloseBalance) return <p>-</p>;

  return Object.entries(expectedCloseBalance).map(([currency, amount]) => {
    const closeBalance = closeCashBalance?.[currency as Currency];
    const difference =
      closeBalance !== undefined ? amount - closeBalance : amount;
    return (
      <p
        className={clsx(
          difference === 0 ? "text-primary-main" : "text-danger-dark",
          "whitespace-nowrap"
        )}
        key={`close_${amount}_${currency}`}
      >
        {difference / 100} {currency}
      </p>
    );
  });
};
