import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

import type { VariantGroup, VariantGroupChoice } from "@repo/types";
import { Button, InputAmount } from "~/components";
import { modalVariantsActions } from "~/providers/store/modal-variants";

type VariantChoicesProps = {
  variant: Omit<VariantGroup, "choices"> & {
    choices: (VariantGroupChoice & { amount: number })[];
  };
};

export const VariantChoices = ({ variant }: VariantChoicesProps) => {
  const variantMaxAmount = getVariantMaximumAmount(variant.maximum);

  const totalChoiceAmount =
    variant.choices.reduce((acc, c) => acc + c.amount, 0) ?? 0;

  const isToggle = variantMaxAmount === 1;

  return (
    <div className="grid grid-cols-3 gap-6">
      {variant.choices.map((choice) => {
        const choiceAmount = choice.amount;

        const isSelected = choiceAmount > 0;

        const canAddMoreThanOne = !(variantMaxAmount === 1 || choice.max === 1);

        const maxAmount = (() => {
          const addableBasedOnChoice =
            (choice.max ?? Number.POSITIVE_INFINITY) - choiceAmount;
          const addableBasedOnVariant = variantMaxAmount - totalChoiceAmount;
          return Math.min(addableBasedOnChoice, addableBasedOnVariant);
        })();

        const toggleChoices = !canAddMoreThanOne && isSelected;

        return (
          <div className="flex h-40 flex-col" key={choice.id}>
            <Button
              className={clsx(
                {
                  "rounded-b-none": isSelected && canAddMoreThanOne,
                  "pointer-events-none":
                    !isToggle && maxAmount === 0 && !toggleChoices,
                },
                "size-full rounded-md p-5 text-2xl font-semibold"
              )}
              disabled={
                isToggle ? false : choiceAmount === 0 && maxAmount === 0
              }
              onClick={() => {
                if (isToggle) {
                  modalVariantsActions.toggleChoice({
                    variantId: variant.id,
                    choiceId: choice.id,
                  });
                } else {
                  // If they can only select one choice and they have already selected one, then deselect it
                  // Like a toggle but for the choices
                  if (toggleChoices) {
                    modalVariantsActions.decrementChoice({
                      variantId: variant.id,
                      choiceId: choice.id,
                    });
                    return;
                  }

                  modalVariantsActions.incrementChoice({
                    variantId: variant.id,
                    choiceId: choice.id,
                  });
                }
              }}
              type="button"
              variant={isSelected ? "primary" : "secondary"}
            >
              <div className="flex flex-col items-center justify-center gap-1">
                <span className="flex items-center gap-2">
                  {isSelected ? <FontAwesomeIcon icon={faCircleCheck} /> : null}
                  {choice.name}
                </span>
                {choice.unitPrice ? (
                  <p className="text-base font-normal">
                    + {choice.displayPrice}
                  </p>
                ) : null}
              </div>
            </Button>

            {isSelected && canAddMoreThanOne ? (
              <div>
                <InputAmount
                  className="!box-border flex w-full justify-between rounded-none rounded-b-md px-6"
                  disableMaxButton={maxAmount === 0}
                  name={choice.id}
                  onChange={() => null}
                  onDecrement={() => {
                    modalVariantsActions.decrementChoice({
                      variantId: variant.id,
                      choiceId: choice.id,
                    });
                  }}
                  onIncrement={() => {
                    modalVariantsActions.incrementChoice({
                      variantId: variant.id,
                      choiceId: choice.id,
                    });
                  }}
                  size="medium"
                  value={choiceAmount}
                  variant="light-green"
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

const getVariantMaximumAmount = (maximum: number | string | null) => {
  if (typeof maximum === "number") {
    return maximum;
  }

  if (typeof maximum === "string") {
    return maximum !== "" ? Number(maximum) : Number.POSITIVE_INFINITY;
  }

  return Number.POSITIVE_INFINITY;
};
