import { Link } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faRefresh,
} from "@fortawesome/pro-solid-svg-icons";

import { T, useT } from "@repo/transifex";
import {
  useAssignTill,
  useCurrentTill,
  useSchoolsByPartner,
  useUnassignedTills,
} from "~/hooks/queries";
import { useDeviceData } from "~/providers/app";
import { Button, DialogLoading, SelectCreatable, TillCard } from "~/components";
import { authActions, useAuth } from "~/providers/store/auth";
import { LicenseLayout } from "~/routes/license/LicenseLayout.tsx";

const LicensePage = () => {
  const { partnerId, tillId } = useAuth();
  const [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null);
  const [selectedTillId, setSelectedTillId] = useState<string | null>(null);

  const { deviceId } = useDeviceData();
  const t = useT();

  const { data: till, status: tillStatus } = useCurrentTill();
  const {
    data: schools,
    status: schoolStatus,
    refetch: refetchSchools,
  } = useSchoolsByPartner(partnerId);
  const { data: tills, status, refetch: refetchTills } = useUnassignedTills();
  const { mutate: assignTill, status: assignStatus } = useAssignTill();

  if (
    status === "pending" ||
    assignStatus === "pending" ||
    schoolStatus === "pending" ||
    (tillId !== null && tillStatus === "pending")
  ) {
    return (
      <LicenseLayout>
        <DialogLoading />
      </LicenseLayout>
    );
  }

  if (status === "error" || schoolStatus === "error") {
    return (
      <LicenseLayout>
        <div className="flex flex-col gap-4">
          <h1 className="text-center text-2xl font-bold text-danger-dark">
            <T _str="Could not load tills" />
          </h1>

          <p className="text-center">
            <T _str="There was an error fetching tills, please check your connection and try again" />
          </p>
        </div>

        <Button
          onClick={() => {
            void refetchSchools();
            void refetchTills();
          }}
        >
          <div className="flex items-center gap-2">
            <FontAwesomeIcon fontSize={16} icon={faRefresh} />
            <T _str="Retry" />
          </div>
        </Button>
      </LicenseLayout>
    );
  }

  if (tillStatus === "success") {
    return (
      <LicenseLayout>
        <h1 className="text-center text-2xl font-bold">
          <T _str="Device already has a license" />
        </h1>

        {till.deviceId === deviceId ? (
          <Link to="/pos">
            <Button className="w-full">
              <T _str="Back to POS" />
            </Button>
          </Link>
        ) : (
          <Button onClick={authActions.logoutTill}>
            <T _str="Assign new license" />
          </Button>
        )}
      </LicenseLayout>
    );
  }

  if (tills.length > 0) {
    return (
      <LicenseLayout>
        <div className="flex flex-col gap-4">
          <h1 className="text-center text-2xl font-bold">
            <T _str="Let's get you started!" />
          </h1>
          <p className="text-center">
            <T _str="To begin, please choose a till below" />
          </p>
        </div>

        <div className="flex flex-col gap-5">
          <TillCard name="This device">
            <div className="flex w-full flex-col gap-1">
              <SelectCreatable
                onChange={(value) => {
                  if (value) {
                    setSelectedSchoolId(value.value);
                  }
                }}
                options={schools.map((school) => ({
                  value: school.id,
                  label: school.name,
                }))}
                placeholder={t("Choose a salesplace")}
              />

              <SelectCreatable
                onChange={(value) => {
                  if (value) {
                    setSelectedTillId(value.value);
                  }
                }}
                options={tills
                  .filter((till) => till.schoolId === selectedSchoolId)
                  .map((till) => ({
                    value: till.id,
                    label: till.name,
                  }))}
                placeholder={t("Choose a license")}
              />
            </div>
          </TillCard>

          <Button
            disabled={!selectedTillId}
            onClick={() => {
              if (!selectedTillId || !deviceId) {
                throw new Error("Till or device id is missing");
              }

              assignTill({
                tillId: selectedTillId,
                deviceId,
              });
            }}
          >
            <T _str="Assign license" />
          </Button>
        </div>
      </LicenseLayout>
    );
  }

  return (
    <LicenseLayout>
      <div className="flex flex-col gap-4">
        <h1 className="text-center text-2xl font-bold">
          <T _str="Oops, we can’t find any tills" />
        </h1>
        <p className="text-center">
          <T _str="Please create a till to continue" />
        </p>
      </div>

      <div className="flex flex-col gap-5">
        <TillCard name="This device">
          <div className="flex w-full items-center justify-center gap-2 rounded-lg bg-danger-backdrop p-4">
            <FontAwesomeIcon
              className="text-danger-dark"
              fontSize={18}
              icon={faCircleExclamation}
            />
            <h1 className="text-lg font-bold text-danger-dark">
              <T _str="No tills detected" />
            </h1>
          </div>
        </TillCard>

        <Button
          onClick={() => {
            void refetchTills();
          }}
        >
          <div className="flex items-center gap-2">
            <FontAwesomeIcon fontSize={16} icon={faRefresh} />
            <T _str="Refresh" />
          </div>
        </Button>
      </div>
    </LicenseLayout>
  );
};

export { LicensePage };
