import type { QueryStatus } from "@tanstack/react-query";
import { clsx } from "clsx";
import moment from "moment-timezone";
import { useState } from "react";

import { T, useT } from "@repo/transifex";
import { Dialog } from "~/components";
import { useLatestUpdates } from "~/providers/app";
import { calendarFormatter } from "~/utils/calendarFormatter";

export const UpdatesInfo = () => {
  const t = useT();
  const [open, setOpen] = useState(false);

  const { last, queries } = useLatestUpdates();

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span
        className="text-white"
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("Last update {day} {time}", {
          day: moment(last).calendar(null, calendarFormatter(t)),
          time: moment(last).format("HH:mm"),
        })}
      </span>

      <Dialog onOutsideClick={handleOnClose} open={open} size="md">
        <div className="flex flex-col p-4">
          <p className="mb-10 text-center text-lg">
            <T _str="Latest updates" />
          </p>
          <UpdateItem
            date={queries.products.last}
            name={t("Products")}
            status={queries.products.status}
          />
          <UpdateItem
            date={queries.profiles.last}
            name={t("Profiles")}
            status={queries.profiles.status}
          />
          <UpdateItem
            date={queries.currentTill.last}
            name={t("Current till")}
            status={queries.currentTill.status}
          />
          <UpdateItem
            date={queries.session.last}
            name={t("Session")}
            status={queries.session.status}
          />
        </div>
      </Dialog>
    </>
  );
};

const UpdateItem = ({
  name,
  date,
  status,
}: {
  name: string;
  date: number;
  status: QueryStatus;
}) => {
  const t = useT();

  return (
    <div className="flex w-full justify-between border-b py-4">
      <p>{name}</p>
      <p
        className={clsx({
          "text-success-dark": status === "success",
          "text-warning-dark": status === "pending",
          "text-danger-dark": status === "error",
        })}
      >
        {status === "success"
          ? `${moment(date).calendar(null, calendarFormatter(t))} ${moment(
              date
            ).format("HH:mm")}`
          : status}
      </p>
    </div>
  );
};
