import { clsx } from "clsx";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";

import { T } from "@repo/transifex";
import { Button, DialogError, DialogLoading, Footer } from "~/components";
import { GridLayout } from "~/components/GridLayout";
import { ShopLayout } from "~/components/ShopLayout";
import { employeeActions } from "~/providers/store/employee";
import { ItemSkeleton } from "~/components/ItemSkeleton";
import { useCurrentTill, useCurrentSchool } from "~/hooks/queries";

import { SelectorItem } from "./SelectorItem";

export const SelectorsPage = () => {
  const navigate = useNavigate();

  const { data: school, status } = useCurrentSchool();
  const { data: till, status: tillStatus } = useCurrentTill();

  // get all the options from the school
  const allOptions = school?.selector?.options ?? [];
  // filter out the designated guest selector from the options to display
  const options =
    tillStatus === "success" && till.type === "self-service"
      ? allOptions.filter((option) => option.name !== till.config.guestSelector && option.hidden !== true)
      : [];

  // If there are 0 or 1 options skip the selector choice
  useEffect(() => {
    if (
      status === "success" &&
      tillStatus === "success" &&
      allOptions.length <= 1
    ) {
      employeeActions.setGroupName(allOptions[0]?.name ?? undefined);
      navigate("/pos");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on status
  }, [status, tillStatus]);

  if (tillStatus === "pending") return <DialogLoading />;
  if (tillStatus === "error" || till.type !== "self-service")
    return (
      <DialogError childrenPosition="top" size="md">
        <T _str="Company selection not available" />
        <Link to="/pos">
          <Button variant="danger">
            <T _str="Back" />
          </Button>
        </Link>
      </DialogError>
    );

  if (status === "pending") {
    return (
      <ShopLayout footer={<Footer />}>
        <GridLayout>
          {Array.from({ length: 16 }).map(() => (
            <ItemSkeleton key={uuidv4()} />
          ))}
        </GridLayout>
      </ShopLayout>
    );
  }

  return (
    <ShopLayout footer={<Footer />}>
      <GridLayout
        className={clsx({
          "pt-40": options.length <= 12,
          "pt-60": options.length <= 8,
          "pt-80": options.length <= 4,
        })}
      >
        {options.map(({ name }) => (
          <SelectorItem groupName={name} key={name} />
        ))}
      </GridLayout>

      {till.config.guestSelector ? (
        <div
          className={clsx("mt-16 flex w-full items-center justify-center", {
            "mb-16": options.length >= 20,
          })}
        >
          <Button
            onClick={() => {
              employeeActions.setGroupName(till.config.guestSelector);
              navigate("/pos");
            }}
            variant="info"
          >
            <T _str="Continue as guest" />
          </Button>
        </div>
      ) : null}
    </ShopLayout>
  );
};
