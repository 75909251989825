import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useCurrentTill } from "~/hooks/queries";
import { useAppDispatch, useCountDownState } from "~/providers/app";
import { basketActions } from "~/providers/store/basket";
import { employeeActions } from "~/providers/store/employee";

import { DialogLoading } from "../DialogLoading";

import { DialogPaymentError } from "./DialogPaymentError";
import { DialogPaymentSuccess } from "./DialogPaymentSuccess";

type DialogPaymentProps = {
  isAttended?: boolean;
} & (
  | {
      status: "error" | "cancelled";
      orderId?: string;
      change?: number;
    }
  | {
      status: "success";
      orderId: string;
      change?: number;
    }
);

export const DialogPayment = ({
  status,
  orderId,
  change,
}: DialogPaymentProps) => {
  const dispatch = useAppDispatch();
  const { count } = useCountDownState();

  const navigate = useNavigate();
  const { categoryId } = useParams();

  const { data: till } = useCurrentTill();
  const isAttended = till?.type === "attended";

  useEffect(() => {
    if (!isAttended) {
      dispatch({ type: "COUNTDOWN_START", payload: 10 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dispatch is optimized
  }, []);

  useEffect(() => {
    if (count === 0) {
      handleResetCountdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- handleResetCountdown is optimized
  }, [count]);

  const handleResetCountdown = () => {
    navigate(isAttended ? formatRedirectUrl(categoryId) : "/pos");
    dispatch({ type: "COUNTDOWN_RESET" });
  };

  if (status === "success") {
    return (
      <DialogPaymentSuccess
        change={change}
        isAttended={isAttended}
        onClose={() => {
          handleResetCountdown();
          basketActions.reset();
          till?.type === "self-service" && basketActions.resetDiningOption();
          employeeActions.reset();
        }}
        orderId={orderId}
      />
    );
  }

  if (status === "error" || status === "cancelled")
    return (
      <DialogPaymentError
        isAttended={isAttended}
        onClose={() => {
          handleResetCountdown();
        }}
        status={status}
      />
    );

  return <DialogLoading />;
};

const formatRedirectUrl = (categoryId: string | undefined) => {
  if (categoryId === undefined) {
    return "/pos";
  }

  if (categoryId.includes("/")) {
    return `/pos/${categoryId.replace("/", "%2F")}`;
  }

  return `/pos/${categoryId}`;
};
