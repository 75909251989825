import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

import { useT } from "@repo/transifex";
import { useAuth } from "~/providers/store/auth";
import { SettingsLayout } from "~/components";

import { TillSelector } from "./TillSelector";

const ReportsPage = () => {
  const navigate = useNavigate();
  const t = useT();

  const { tillId: tillIdFromParams } = useParams();

  const { tillId } = useAuth();

  useEffect(() => {
    if (tillIdFromParams) return;

    navigate(`/settings/reports/${tillId}`);
  }, [navigate, tillId, tillIdFromParams]);

  return (
    <SettingsLayout action={<TillSelector />} title={t("Reports")}>
      <Outlet />
    </SettingsLayout>
  );
};

export { ReportsPage };
