import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";

import { Dialog, type DialogProps } from "./Dialog";

type DialogSuccessProps = Partial<DialogProps> & {
  title?: string;
};

export const DialogSuccess = ({
  children,
  title,
  ...props
}: DialogSuccessProps) => {
  return (
    <Dialog size="sm" {...props}>
      <div className="flex flex-col items-center p-8">
        <div className="mb-6 flex size-8 items-center justify-center rounded-full bg-primary-main text-primary-contrast">
          <FontAwesomeIcon className="h-1/2" icon={faCheck} />
        </div>

        {title ? (
          <p className="text-center text-xl font-semibold">
            <T _str={title} />
          </p>
        ) : null}

        {children}
      </div>
    </Dialog>
  );
};
