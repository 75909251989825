import { useState } from "react";

import { useT } from "@repo/transifex";
import type { Profile } from "@repo/types";
import { useProfileAuth } from "~/hooks/queries";
import { isApiError } from "~/utils/api";

import { PinInput } from "./PinInput";
import { PinKeyboard } from "./PinKeyboard";

type DialogPinProps = {
  profileId: Profile["id"];
};

export const ProfilePin = ({ profileId }: DialogPinProps) => {
  const [pin, setPin] = useState("");

  const { mutate: authProfile, status, error, reset } = useProfileAuth();
  const t = useT();

  const handleInputChange = (value: number) => {
    if (status === "error") reset();
    if (value === -1) {
      setPin((prev) => prev.substring(0, prev.length - 1));
      return;
    }

    if (pin.length === 4) return;

    const newPin = pin + value.toString();

    setPin(newPin);

    if (newPin.length === 4) {
      authProfile(
        { profileId, pin: newPin },
        {
          onError: () => {
            setPin("");
          },
        }
      );
    }
  };

  let errorMessage;
  if (status === "error") {
    errorMessage = isApiError(error)
      ? error.body.message
      : t("Something went wrong");
  }

  return (
    <div className="flex flex-col items-center gap-2">
      <div className="flex flex-nowrap justify-center">
        <PinInput pin={pin} status={status} />
      </div>

      <PinKeyboard error={errorMessage} onInputChange={handleInputChange} />
    </div>
  );
};
