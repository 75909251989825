import { useNavigate } from "react-router-dom";

import { T } from "@repo/transifex";
import { Dialog, DialogError, DialogLoading } from "~/components";
import { useSendWebEvent } from "~/hooks";
import { useAppContext, useAppDispatch } from "~/providers/app";

const PrintersPage = () => {
  const {
    hardware: {
      discovery: { printers, status },
    },
  } = useAppContext();

  const dispatch = useAppDispatch();
  const sendWebEvent = useSendWebEvent();
  const navigate = useNavigate();

  const handleDiscoveryReset = () => {
    dispatch({ type: "PRINTER_DISCOVER_RESET" });
  };

  if (status === "loading" || status === "idle")
    return <DialogLoading backUrl=".." onOutsideClick={handleDiscoveryReset} />;

  if (status === "error" || !printers.length)
    return (
      <DialogError
        backUrl=".."
        error="No printers found"
        onOutsideClick={handleDiscoveryReset}
      />
    );

  return (
    <Dialog backUrl=".." onOutsideClick={handleDiscoveryReset} size="md">
      <div className="flex w-full flex-col p-8">
        <p className="mx-auto mb-16 text-3xl">
          <T _str="Choose Device" />
        </p>

        {printers.map((printer) => (
          <button
            className="border-y p-4 text-left hover:bg-gray-100"
            key={printer.identifier}
            onClick={() => {
              sendWebEvent({
                type: "PRINTER_SELECT_REQUEST",
                payload: printer,
              });
              handleDiscoveryReset();
              navigate("..");
            }}
            type="button"
          >
            {printer.interfaceType} - {printer.identifier} - {printer.model}
          </button>
        ))}
      </div>
    </Dialog>
  );
};

export { PrintersPage };
