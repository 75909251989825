import { priceFormatter } from "@repo/system";
import { T, useT } from "@repo/transifex";
import { RadioButton } from "~/components/RadioButton";
import { useAppContext, useBasketProducts } from "~/providers/app";
import { useAuth } from "~/providers/store/auth";
import { refundActions, useRefundInfo } from "~/providers/store/refund";

import { Button } from "../../Button";
import type { DialogProps } from "../../Dialog";
import { Dialog } from "../../Dialog";

type DialogChooseRefundMethod = {
  isFullSwish?: boolean;
  onConfirm: () => void;
} & Partial<DialogProps>;

export const DialogChooseRefundMethod = ({
  isFullSwish,
  onConfirm,
  ...dialogProps
}: DialogChooseRefundMethod) => {
  const t = useT();

  const {
    meta: { currency },
  } = useBasketProducts();

  const {
    hardware: { printer },
  } = useAppContext();

  const { locale } = useAuth();

  const refundInfo = useRefundInfo();

  const refundedOrderPrice = refundInfo.selectedOrderLines.reduce(
    (acc, { amount, unitPrice }) => acc + amount * unitPrice,
    0
  );

  return (
    <Dialog {...dialogProps} size="md">
      <div className="flex flex-col items-center justify-center text-center">
        <div className="mb-8 flex w-full flex-col gap-3 px-8 py-5">
          <h2 className="text-xl font-bold text-text-primary">
            <T _str="Refund" />
          </h2>
          <p>
            <T _str="Choose refund method" />
          </p>
          <div className="flex flex-col">
            {isFullSwish ? (
              <div className="flex items-center justify-between border-y border-divider-main py-4">
                <RadioButton
                  checked={refundInfo.refundMethod === "swish"}
                  label={t("Swish")}
                  onClick={() => {
                    refundActions.setRefundMethod("swish");
                  }}
                />
                <p>
                  {priceFormatter({
                    value: refundedOrderPrice,
                    currency,
                    locale,
                  })}
                </p>
              </div>
            ) : (
              <div className="flex items-center justify-between border-y border-divider-main py-4">
                <RadioButton
                  checked={refundInfo.refundMethod === "card"}
                  label={t("Card")}
                  onClick={() => {
                    refundActions.setRefundMethod("card");
                  }}
                />
                <p>
                  {priceFormatter({
                    value: refundedOrderPrice,
                    currency,
                    locale,
                  })}
                </p>
              </div>
            )}

            {printer?.hasCashDrawer ? (
              <div className="flex items-center justify-between border-b border-divider-main py-4">
                <RadioButton
                  checked={refundInfo.refundMethod === "cash"}
                  label={t("Cash")}
                  onClick={() => {
                    refundActions.setRefundMethod("cash");
                  }}
                />
                <p>
                  {priceFormatter({
                    value: refundedOrderPrice,
                    currency,
                    locale,
                  })}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col gap-5 bg-background-secondary px-8 py-5">
        <div className="flex items-center justify-between">
          <p>
            <T _str="To be refunded" />
          </p>
          {priceFormatter({ value: refundedOrderPrice, currency, locale })}
        </div>

        <div className="flex gap-3">
          <Button
            onClick={() => dialogProps.onOutsideClick?.()}
            variant="danger"
          >
            <T _str="Cancel" />
          </Button>

          <Button className="flex-1" onClick={onConfirm}>
            <T _str="Confirm" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
