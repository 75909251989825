import { useNavigate } from "react-router-dom";

import { T } from "@repo/transifex";

import { DialogError } from "./DialogError";
import { Button } from "./Button";

type OrderCreateErrorDialogProps = {
  error: string;
  open: boolean;
  reset: () => void;
};

export const OrderCreateErrorDialog = ({
  error,
  open,
  reset,
}: OrderCreateErrorDialogProps) => {
  const navigate = useNavigate();

  if (!open) return null;

  const handleClose = () => {
    navigate("/pos");
    reset();
  }

  return (
    <DialogError
      backdropVariant="error"
      className="w-full max-w-md"
      error={error}
      hideSupport
      onOutsideClick={handleClose}
    >
      <Button className="w-full" onClick={handleClose} variant="secondary">
        <T _str="Close" />
      </Button>
    </DialogError>
  );
};
