import { faCircleX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

import { T } from "@repo/transifex";
import { PWA_ENV } from "~/utils/constants";

import { KanplaLogo } from "./KanplaLogo";

type LayoutProps = {
  children: React.ReactNode;
  className?: string;
};
export const LayoutLogo = ({ children, className }: LayoutProps) => {
  return (
    <main className="flex min-h-screen w-full flex-col items-center bg-background-primary">
      <nav className="flex h-16 w-full self-start p-4" id="loginNav">
        <div className="w-40">
          <KanplaLogo />
        </div>
      </nav>

      {PWA_ENV !== "production" ? (
        <div className="absolute bottom-10 rounded-md bg-orange-500 px-3 py-2 font-bold uppercase text-white">
          {PWA_ENV}
        </div>
      ) : null}

      <div className={clsx("flex size-full flex-1 justify-center", className)}>
        {children}
      </div>
    </main>
  );
};

export const LayoutError = ({
  children,
  error = "",
  className,
}: LayoutProps & { error?: string }) => {
  return (
    <LayoutLogo className="items-center">
      <div
        className={clsx(
          "flex h-full flex-col items-center justify-center bg-white",
          className
        )}
      >
        <FontAwesomeIcon
          className="text-danger-dark"
          icon={faCircleX}
          size="2x"
        />

        <h1 className="mb-8 mt-5 text-center text-xl">
          <T _str={error} />
        </h1>

        {children}
      </div>
    </LayoutLogo>
  );
};
