import type { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import {
  faCashRegister,
  faCreditCard,
  faLifeRing,
  faPhone,
  faPrint,
  faRefresh,
  faTablet,
  faWeightScale,
} from "@fortawesome/pro-light-svg-icons";
import {
  faArrowRight,
  faHome,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import { useState, type ReactNode } from "react";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { faIntercom } from "@fortawesome/free-brands-svg-icons";
import { usePostHog } from "posthog-js/react";

import { T, useT } from "@repo/transifex";
import { getSupportInfoFromCountryCode } from "@repo/system";
import { Button } from "~/components";
import { useSendWebEvent } from "~/hooks";
import { useAuth } from "~/providers/store/auth";
import { useAppContext, useDeviceData } from "~/providers/app";
import { useIntercomCreateTicket } from "~/hooks/queries/intercom";
import { useCurrentTill } from "~/hooks/queries";

import { InfoModal } from "./InfoModal";

const B2B_BASE_URL = "https://b2b.help.kanpla.dk/en/";

const SupportPage = () => {
  const t = useT();
  const posthog = usePostHog();
  const queryClient = useQueryClient();

  const { country } = useAuth();

  const sendWebEvent = useSendWebEvent();
  const { phone } = getSupportInfoFromCountryCode(country);

  const {
    hardware: { printer },
  } = useAppContext();

  return (
    <div className="flex size-full flex-col">
      <div className="w-full flex-1 overflow-auto p-8 scrollbar-hide">
        <h1 className="mb-6 text-3xl">
          <T _str="Need help?" />{" "}
          <span className="text-text-disabled">
            {t("Try these guides to troubleshoot")}
          </span>
        </h1>

        <div className="mb-4 grid grid-cols-4 gap-4">
          <SupportLink
            icon={faCreditCard}
            name={t("Terminal issues")}
            url={buildSupportLink(
              "articles/195989-my-terminal-can-t-connect-to-ethernet"
            )}
          />
          <SupportLink
            icon={faCashRegister}
            name={t("Cashdrawer issues")}
            url={buildSupportLink(
              "articles/168990-star-mpop-cash-drawer-manual"
            )}
          />
          <SupportLink
            icon={faPrint}
            name={t("Printer issues")}
            url={buildSupportLink(
              printer?.model === "mC_Print3"
                ? "articles/194725-star-mc-3-printer-manual"
                : "articles/168996-star-mc-2-printer-manual"
            )}
          />
          <SupportLink icon={faWeightScale} name={t("Scale issues")} url="" />
        </div>

        <div className="mb-6 grid grid-cols-2 gap-4">
          <SupportLink
            icon={faTablet}
            name={t("Device issues")}
            url={buildSupportLink("articles/169210-adyen-p400-plus-manual")}
          />
          <SupportLink
            hideSubtitle
            icon={faLifeRing}
            name={t("All troubleshooting guides")}
            url={buildSupportLink("collections/288908-pos")}
          />
        </div>

        <div className="mb-4 w-full border" />

        <h2 className="mb-4 text-lg text-text-disabled">
          {t("Still having issues?")}
        </h2>

        <div className="grid grid-cols-2 gap-4">
          <SupportAction icon={faRefresh} name={t("Try reloading the app")}>
            <Button
              onClick={() => {
                sendWebEvent({ type: "RELOAD_WEBVIEW" });
                posthog.capture("support_page:reload_app_button_click");
                queryClient.removeQueries();
              }}
            >
              {t("Reload")}
            </Button>
          </SupportAction>
          <SupportAction icon={faPhone} name={t("Call support")}>
            <div className="flex flex-col items-end">
              <a
                className="text-xl font-bold text-primary-light"
                href={`tel:${phone}`}
              >
                {phone}
              </a>
              <p className="text-text-disabled">8:15 - 15:00 CEST</p>
            </div>
          </SupportAction>
        </div>
      </div>

      <SupportFooter />
    </div>
  );
};

const buildSupportLink = (path: string) => {
  return `${B2B_BASE_URL}/${path}`;
};

type SupportLinkProps = {
  url: string;
  name: string;
  icon: IconDefinition;
  hideSubtitle?: boolean;
};

const SupportLink = ({ url, name, icon, hideSubtitle }: SupportLinkProps) => {
  const t = useT();
  const posthog = usePostHog();

  return (
    <Link
      className={clsx({ "opacity-40 pointer-events-none": !url })}
      onClick={() => {
        posthog.capture("support_page:guide_link_click", { url });
      }}
      rel="noopener noreferrer"
      target="_blank"
      to={url}
    >
      <div className="flex h-52 flex-col items-start justify-between rounded-lg bg-background-secondary px-6 py-8">
        <FontAwesomeIcon icon={icon} size="3x" />

        <p className="text-2xl">{name}</p>

        <p
          className={clsx("text-primary-light", {
            hidden: hideSubtitle,
          })}
        >
          {t("See guides")}
          <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
        </p>
      </div>
    </Link>
  );
};

type SupportActionProps = {
  children: ReactNode;
  name: string;
  icon: IconDefinition;
};

const SupportAction = ({ children, name, icon }: SupportActionProps) => {
  return (
    <div className="flex h-20 items-center justify-between rounded-lg bg-background-secondary px-6 py-10">
      <div className="flex items-center gap-3 text-lg">
        <FontAwesomeIcon icon={icon} />
        <p>{name}</p>
      </div>

      {children}
    </div>
  );
};

export const SupportFooter = () => {
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const { isKanplaAdmin } = useAuth();
  const { deviceId } = useDeviceData();
  const { data: till, status: tillStatus } = useCurrentTill();

  const { mutate: createIntercomTicket, status } = useIntercomCreateTicket();

  return (
    <>
      <div className="flex h-20 w-full items-center justify-between bg-background-secondary px-8">
        <Link to="/menu">
          <button className="flex items-center gap-2.5" type="button">
            <FontAwesomeIcon icon={faHome} />
            <T _str="Home" />
          </button>
        </Link>

        {isKanplaAdmin ? (
          <Button
            disabled={tillStatus !== "success" || status === "success"}
            icon={faIntercom}
            loading={status === "pending"}
            onClick={() => {
              createIntercomTicket({ deviceId, sessionId: till?.sessionId });
            }}
            variant="info"
          >
            {status === "success" ? (
              <T _str="Sent!" />
            ) : (
              <T _str="Intercom ticket" />
            )}
          </Button>
        ) : null}

        <button
          className="flex items-center gap-2.5"
          onClick={() => {
            setOpenInfoModal(true);
          }}
          type="button"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
          <T _str="App info" />
        </button>
      </div>

      {openInfoModal ? (
        <InfoModal
          onClose={() => {
            setOpenInfoModal(false);
          }}
        />
      ) : null}
    </>
  );
};

export { SupportPage };
