import type { ReactNode } from "react";
import { faCashRegister } from "@fortawesome/pro-light-svg-icons";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

import { T } from "@repo/transifex";

import { Button } from "./Button";
import { TillStatusButton } from "./TillStatusButton";

type TillCardProps = {
  name: string;
  children?: ReactNode;
  selected?: boolean;
  className?: string;
  nameClassName?: string;
  status?: "open" | "closed";
  showWarning?: boolean;
};

export const TillCard = ({
  name,
  children,
  status,
  nameClassName,
  className,
  selected = false,
  showWarning,
}: TillCardProps) => {
  return (
    <TillCardLayout className={className}>
      <div className="flex w-full flex-row-reverse justify-between">
        {status ? <TillStatusButton status={status} /> : null}
        {status === "open" && showWarning ? (
          <div className="flex aspect-square h-8 content-center rounded-md bg-warning-backdrop p-1 text-warning-main">
            <FontAwesomeIcon className="m-auto" icon={faWarning} />
          </div>
        ) : null}
      </div>

      <div className="flex flex-col items-center gap-y-4">
        <FontAwesomeIcon fontSize={55} icon={faCashRegister} />

        <h2 className={clsx("text-2xl font-bold", nameClassName)}>{name}</h2>
      </div>

      {children ? (
        <div className="mt-4 flex w-full flex-col items-center justify-center gap-4">
          {children}
        </div>
      ) : null}

      {selected ? (
        <div className="mt-4 flex w-full justify-center">
          <Button className="pointer-events-none" size="small" variant="light">
            <T _str="This device" />
          </Button>
        </div>
      ) : null}
    </TillCardLayout>
  );
};

export const TillCardSkeleton = () => (
  <TillCardLayout className="animate-pulse" />
);

const TillCardLayout = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) => (
  <div
    className={clsx(
      "flex min-h-[200px] min-w-[260px] flex-col items-center justify-center rounded-lg bg-gray-200 px-3 py-4",
      className,
    )}
  >
    {children}
  </div>
);
