import {
  faPlus,
  faRightLeft,
  faWifiSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import { useState } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";

import { T, useT } from "@repo/transifex";
import {
  Button,
  DialogCancelSplitPayment,
  DialogError,
  DialogLoading,
  Footer,
} from "~/components";
import { ShopLayout } from "~/components/ShopLayout";
import { useCountdown } from "~/hooks";
import { useCurrentTill, useSession } from "~/hooks/queries";
import { useSplitPaymentFlow } from "~/providers/app";
import { useIsOnline } from "~/providers/store/auth";
import { useDiningOption } from "~/providers/store/basket";
import { useEmployee } from "~/providers/store/employee";

import { HeaderCategories } from "./HeaderCategories";

const PosLayout = () => {
  const { groupName } = useEmployee();
  const diningOption = useDiningOption();

  const { pathname } = useLocation();

  const t = useT();

  const {
    data: till,
    status: tillStatus,
    error: tillError,
    refetch: refetchTill,
  } = useCurrentTill();
  const {
    data: session,
    status: sessionStatus,
    error: sessionError,
    refetch: refetchSession,
  } = useSession(till?.sessionId);

  const isOnline = useIsOnline();
  useCountdown();

  const { isActive: isSplitPaymentActive } = useSplitPaymentFlow();

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  if (
    tillStatus === "pending" ||
    (till?.sessionId && sessionStatus === "pending")
  ) {
    return <DialogLoading title={t("Loading till and session")} />;
  }

  if (tillError || sessionError) {
    return (
      <DialogError
        error={
          tillError?.message ??
          sessionError?.message ??
          t("There was an unknown error loading till and session")
        }
      >
        <Button
          onClick={() => {
            void refetchTill();
            void refetchSession();
          }}
        >
          <T _str="Try again" />
        </Button>
      </DialogError>
    );
  }

  if (!till.sessionId || !session) {
    return (
      <DialogError error="Till is closed">
        <Link to={`/settings/tills/${till.id}/open`}>
          <Button>
            <T _str="Open Till" />
          </Button>
        </Link>
      </DialogError>
    );
  }

  if (!session.isValid) {
    return (
      <DialogError error="A register needs to be closed from yesterday">
        <Link to={`/settings/tills/${till.id}`}>
          <Button>
            <T _str="Close Till" />
          </Button>
        </Link>
      </DialogError>
    );
  }

  if (
    till.type === "self-service" &&
    till.config.hasEmployeeCardReader &&
    groupName === null
  ) {
    return <Navigate replace to="/employee-card" />;
  }

  if (
    till.type === "self-service" &&
    till.config.selectorSetup === "show-selectors" &&
    groupName === null
  ) {
    return <Navigate replace to="/selectors" />;
  }

  const fields = till?.preCheckoutTextInputs ?? [];
  const options = fields[0]?.options ?? [];
  // if there are text inputs and if there are options inside of them, redirect to dining options page
  const hasDiningOptions = Boolean(fields.length && options.length);
  if (
    hasDiningOptions &&
    diningOption === null &&
    ((till.type === "self-service" &&
      (groupName !== null || till.config.selectorSetup !== "show-selectors")) ||
      till.type === "attended")
  ) {
    return <Navigate replace to="/dining-options" />;
  }

  return (
    <ShopLayout
      footer={
        <Footer>
          {till.type === "attended" && (
            <div className="flex w-full items-center justify-between">
              <span
                onClick={() => {
                  isSplitPaymentActive && setCancelDialogOpen(true);
                }}
              >
                <Link
                  className={clsx({
                    "pointer-events-none": isSplitPaymentActive,
                  })}
                  state={{ showBackButton: true }}
                  to="/settings/transactions"
                >
                  <Button icon={faRightLeft} variant="transparent">
                    <T _str="Transactions" />
                  </Button>
                </Link>
              </span>

              <div className="flex items-center gap-3">
                {!isOnline && (
                  <div className="flex items-center gap-2.5 text-warning-dark">
                    <FontAwesomeIcon icon={faWifiSlash} />
                    <p>
                      <T _str="Connection lost" />
                    </p>
                  </div>
                )}
                <Link
                  className={clsx({
                    "pointer-events-none": isSplitPaymentActive,
                  })}
                  to={`${pathname}/custom-product`}
                >
                  <Button
                    disabled={isSplitPaymentActive}
                    icon={faPlus}
                    variant="light"
                  >
                    <T _str="Create" />
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </Footer>
      }
      header={<HeaderCategories />}
    >
      <Outlet />

      {cancelDialogOpen ? (
        <DialogCancelSplitPayment
          onClose={() => {
            setCancelDialogOpen(false);
          }}
        />
      ) : null}
    </ShopLayout>
  );
};

export { PosLayout };
